import React, { useState } from 'react';
import Logoazul from '../img/logoazulmabel.png';
import Camionmabel from '../img/camionmabel.png';
import Logoblanco from '../img/logoblancomabel.png';
import { SlCallOut } from 'react-icons/sl';
import { BiMenu } from 'react-icons/bi';
import { FaWhatsapp, FaFacebook, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import '../css/Contacto.css';

const Contacto = () => {
    const [menuVisible, setMenuVisible] = useState(false);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    return (
        <div>
            <div className='contenedorglobalheaderp'>
                <div className='uno'>
                    <h1 className='titlesomos'>Contactenos</h1>
                    <button className='buttonmenu' onClick={toggleMenu}><BiMenu /></button>

                </div>
                <div className='dos'>
                    {menuVisible && (

                        <ul className='menu'>
                            <button className='buttonmenu x' onClick={toggleMenu}>X</button>
                            <Link className='enlacesmenu' to='/' ><li>Inicio</li></Link>
                            <Link className='enlacesmenu' to='/nosotros' ><li>Nosotros</li></Link>
                            <Link className='enlacesmenu' to='/' ><li>Servicios</li></Link>
                            <Link className='enlacesmenu' to='/contacto' ><li>Contacto</li></Link>
                        </ul>
                    )}
                    <img className='logop' src={Logoazul} alt='mabel' />
                    <h3 className='textologo'>Servicio de carga Lima - Chiclayo</h3>
                </div>
                <div className='tres'>
                    <img className='camionmabel' src={Camionmabel} alt='transporte mabel' />
                </div>
            </div>
            <div className='contenedorcaracteristicas' >
                <h1 className='titlecaracteristicas' >
                    Contactenos, sera un gusto atenderle.
                </h1>
                <div className='contformularioicon' >
                    <form className='formulariocontacto' >
                        <label className='labelform' htmlFor="nombre">Nombre:</label>
                        <input className='inputform' type="text" placeholder='Nombre' />
                        <label className='labelform' htmlFor="correo">Correo electrónico:</label>
                        <input className='inputform' type="email" placeholder='Correo electrónico' />
                        <label className='labelform' htmlFor="telefono">Asunto:</label>
                        <input className='inputform' type="text" placeholder='Asunto' />
                        <label className='labelform' htmlFor="mensaje">Mensaje:</label>
                        <input className="texttareaform" type="text" placeholder='Mensaje' />

                        <button className='buttonform' type='submit' >Enviar</button>

                    </form>
                    <div className="conticonl">
                        <img className='logobl' src={Logoblanco} alt="mabel" />
                        <FaWhatsapp className='iconl' />
                        <FaFacebook className='iconl' />
                        <FaYoutube className='iconl' />
                    </div>
                    <div className="contenedorbotonescontactar">
                            <div className="botonllamarlc">
                                <h2 className='botonllamartexto' >CHICLAYO</h2>
                                <div className="btnlima">
                                    <SlCallOut className='iconllamar' />
                                    <a href="#" className="botonllamar" >(01)-7734802 <br /> +51951061446 <br /> +51951060596</a>
                                </div>
                            </div>

                            <div className="botonllamarlc">
                                <h2 className='botonllamartexto' >LIMA</h2>
                                <div className="btnlima">
                                    <SlCallOut className='iconllamar' />
                                    <a href="#" className="botonllamar" >(074)-638859 <br /> +51955652350 <br /> +51942102549</a>
                                </div>
                            </div>
                        </div>
                        
                </div>
                
            </div>
            <div className="contmapa">
                        <iframe className='mapa' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15607.038610337811!2d-77.04721461284178!3d-12.060051099999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c8be864cd783%3A0xde097c72b4c3e89e!2sTransporte%20Mabel!5e0!3m2!1ses!2spe!4v1685218773253!5m2!1ses!2spe" width="600" height="450" style={{border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="footerbanner">
            <h5 className='textofooterb' >Transportes Mabel S.A.C - Diseñado por <span className='spanfooterb' > Inticap</span> </h5>
        </div>
        </div>
    );
}

export default Contacto;