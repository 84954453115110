import React from 'react';
import Iconouno from '../img/primericono.png';
import Iconodos from '../img/segundoicono.png';
import Iconotres from '../img/tercericono.png';
import Iconocuatro from '../img/cuartoicono.png';
import Logoblanco from '../img/logoblancomabel.png';
import '../css/Caracteristicas.css'

const Caracteristicas = () => {
    return (
        <div className='contenedorcaracteristicas' >
          <h1 className='titlecaracteristicas' >
          TRANSPORTE DE CARGA ESPECIALIZADO
          </h1>
          <div className='contcaraceimg' >
            <p className='textocaracteristica i' >
            Bienvenidxs a Transporte Mabel, una empresa dedicada al transporte de carga a nivel nacional. Desde nuestra fundación, hemos brindado un servicio de alta calidad y confiabilidad a nuestros clientes.
            </p>
            <img className='iconocaracteristica ib' src={Iconouno} alt="" />
          </div>
          <div className='contcaraceimg' >
          <img src={Iconodos} alt="" />
            <p className='textocaracteristica d' >
            Nos enorgullece contar con un equipo altamente capacitado y comprometido con la satisfacción del cliente. Cada uno de nuestros colaboradores trabaja arduamente para garantizar que su carga llegue a su destino de manera segura y en tiempo y forma.
            </p>
            
          </div>
          <div className='contcaraceimg' >
          <p className='textocaracteristica i' >
          Además, contamos con una flota de vehículos modernos y equipados con la última tecnología para asegurar que su carga se transporte en las mejores condiciones posibles. Todos nuestros vehículos son sometidos a rigurosos controles de mantenimiento para garantizar su operatividad y evitar cualquier retraso en la entrega.
            </p>
            <img className='ib' src={Iconotres} alt="" />
          </div>
          <div className='contcaraceimg' >
          <img src={Iconocuatro} alt="" />
          <p className='textocaracteristica d' >
          En Transporte Mabel, entendemos la importancia de su carga y nos esforzamos por brindarle un servicio de calidad a un precio competitivo. Trabajamos con una amplia variedad de sectores, desde la industria manufacturera hasta la alimentaria, para transportar todo tipo de mercancías.
            </p>
            
          </div>
          <div className='contcaraceimg' >
          <p className='textocaracteristica i' >
          Si está buscando un servicio de transporte de carga confiable y de alta calidad a nivel nacional, no dude en contactarnos. Estamos comprometidos con su satisfacción y esperamos poder servirle pronto.
            </p>
            <img className='ib' src={Logoblanco} alt="" />
          </div>
        </div>
    );
}

export default Caracteristicas;