import React, { useState } from 'react';
import '../css/Slider2.css';

const Slider2 = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="slider-container">
      <div className="slider">
        {images.map((imageUrl, index) => (
          <div
            key={index}
            className={`slide ${index === currentIndex ? 'active' : ''}`}
            style={{
              backgroundImage: `url(${imageUrl})`,
              width: '100%',
              height: '660px' // Ajustar el ancho al 100% de la página
            }}
          ></div>
        ))}
      </div>
      <button className="prev-button" onClick={prevSlide}>
      ←
      </button>
      <button className="next-button" onClick={nextSlide}>
      →
      </button>
    </div>
  );
};

export default Slider2;
