import React from 'react';
import Logonegro from '../img/logonegromabel.png'
import '../css/Banner.css';
import { SlCallOut } from 'react-icons/sl';

const Banner = () => {
    return (
        <div>
        <div className="contbanner">
        <h1 className='contbannertexto' >Distribución <br /> <span className='spanbanner' >CHICLAYO - LIMA</span> </h1>
        <img className='contbannerimg' src={Logonegro} alt='mabel' />
        </div>
        <div className="lineaamarilla">
            <h2 className='lineatexto' >
            TRANSPORTE MABEL, SIEMPRE LOS MEJORES.
            </h2>
        </div>
        <div className="contenedorbotones">
        <a href="" className="btncontactarb">CONTACTAR</a>
        <div className="contbotonesllamar">

        <div className="botonllamarlc">
            <h2 className='botonllamartexto' >CHICLAYO</h2>
            <div className="btnlima">
            <SlCallOut className='iconllamar' />
            <a href="#" className="botonllamar" >(01)-7734802 <br /> +51951061446 <br /> +51951060596</a>
            </div>
        </div>

        <div className="botonllamarlc">
            <h2 className='botonllamartexto' >LIMA</h2>
            <div className="btnlima">
            <SlCallOut className='iconllamar' />
            <a href="#" className="botonllamar" >(074)-638859 <br /> +51955652350 <br /> +51942102549</a>
            </div>
        </div>
        </div>
        </div>
        <div className="footerbanner">
            <h5 className='textofooterb' >Transportes Mabel S.A.C - Diseñado por <span className='spanfooterb' > Inticap</span> </h5>
        </div>
        </div>
    );
}

export default Banner;