import React, { useEffect, useState } from 'react';
import Logoazul from '../img/logoazulmabel.png';
import Camionmabel from '../img/camionmabel.png';
import Banner from '../components/Banner';
import Caracteristicas from '../components/Caracteristicas';
import { BiMenu } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import '../css/Nosotros.css';

const Nosotros = () => {
    const [menuVisible, setMenuVisible] = useState(false);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    return (
        <div>
            <div className='contenedorglobalheaderp'>
                <div className='uno'>
                    <h1 className='titlesomos'>¿Quienes Somos?</h1>
                    <button className='buttonmenu' onClick={toggleMenu}><BiMenu  /></button>
                    
                </div>
                <div className='dos'>
                {menuVisible && (
                    
                <ul className='menu'>
                    <button className='buttonmenu x' onClick={toggleMenu}>X</button>
                    <Link className='enlacesmenu' to='/' ><li>Inicio</li></Link>
                    <Link className='enlacesmenu' to='/nosotros' ><li>Nosotros</li></Link>
                    <Link className='enlacesmenu' to='/' ><li>Servicios</li></Link>
                    <Link className='enlacesmenu' to='/contacto' ><li>Contacto</li></Link>
                </ul>
            )}
                    <img className='logop' src={Logoazul} alt='mabel' />
                    <h3 className='textologo'>Servicio de carga Lima - Chiclayo</h3>
                </div>
                <div className='tres'>
                    <img className='camionmabel' src={Camionmabel} alt='transporte mabel' />
                </div>
            </div>
            <Caracteristicas />
            <Banner />
            
        </div>
    );
};

export default Nosotros;
