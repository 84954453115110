import './App.css';
import Principal from './pages/Principal';
import Principaldos from './pages/Principaldos';
import Nosotros from './pages/Nosotros';
import Contacto from './pages/Contacto';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {

  return (
    <div className="App">
      <BrowserRouter basename={"/portal"}>
      <Routes>
        <Route path="/" element={<Principaldos />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path='/contacto' element={<Contacto />} />
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
