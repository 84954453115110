import React from 'react';
import Camion1 from '../assets/camiones/cami1.png';
import Camion2 from '../assets/camiones/cami2.png';
import Camion3 from '../assets/camiones/cami3.png';
import Camion4 from '../assets/camiones/cami4.png';
import Camion5 from '../assets/camiones/cami5.png';
import Camion6 from '../assets/camiones/cami6.png';
import '../css/Vehiculos.css';

const Vehiculos = () => {
    return (
        <div>
            <div className="contglobalvehiculos">
                <div className="contvehiculos">
                    {/* <img className='camiones' src={Camion1} alt="" />
                    <img className='camiones' src={Camion2} alt="" />
                    <img className='camiones' src={Camion3} alt="" /> */}
                    <img className='camiones' src={Camion4} alt="" />
                    <img className='camiones' src={Camion5} alt="" />
                    <img className='camiones' src={Camion6} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Vehiculos;