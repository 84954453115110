import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BiMenu } from 'react-icons/bi';
import { FaWhatsapp, FaFacebook, FaYoutube, FaDollarSign } from 'react-icons/fa';
import Nav from '../components/Nav';
import { GiGps } from 'react-icons/gi';
import { BsFillRocketTakeoffFill, BsFillTelephoneFill } from 'react-icons/bs';
import Logo from '../img/logonegromabel.png';
import Camionuno from '../img/camionmabel1.png';
import Camiondos from '../img/camiondospageprincipal.png';
import Camionprincipal from '../img/camionmabel2.png';
import Logoblackfondopd from '../img/logoblackfondopd.png';
import Camioncar from '../img/camionmabelcaracteristicas.png';
import Logoblacks from '../img/logoblacksimple.png';
import Vehiculos from '../components/Vehiculos';
import Slider2 from '../components/Slider2';
import '../css/Principal.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importa el CSS de Bootstrap

const Principaldos = () => {
    const [menuVisible, setMenuVisible] = useState(false);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    var images = [
        'https://www.revistaapolice.com.br/wp-content/uploads/2017/03/transporte.jpg',
        'https://img.freepik.com/foto-gratis/trafico-carretera_1359-4.jpg',
        'https://img.freepik.com/foto-gratis/trafico-carretera_1359-4.jpg',
      ];

    return (
        <div>
            {/* Menú */}
            <nav className="navbar navbar-expand-lg navbar-light bg-red" style={{ borderBottom: "2px solid black" }}>
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src={Logo} alt="Transporte Mabel" width="100" />
                    </Link>
                    <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${menuVisible ? 'show' : ''}`}>
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" to="/" >Inicio</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/nosotros" >Nosotros</Link>
                            </li>
                            <li className='nav-item'>
                                <Link className='nav-link' to='/servicios' >Servicios</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contacto" >Contacto</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="color-div">
               

                {/* <div className='contimg'>
                    <img className='camiondosimgpd' src={Camionuno} alt="" />
                    <img className='campri' src={Camionprincipal} alt="" />
                </div> */}
                <Slider2 images={images} />
            </div>
            <div className="contenedorcaracteristicas">
                <div className="contseccion">
                    <div className='contactopd' >
                        <div className="logotitle">
                            <img src={Logoblackfondopd} alt="" />
                            <div className="textopd">
                                <h1 className='textopd__title' >Transporte Mabel S.A.C</h1>
                                <p className='textopd__parrafo' >Logística, transporte y
                                    Distribución.</p>
                            </div>
                        </div>
                        <div className="redespd">
                            <h1 className='redesps__title' >Contáctanos</h1>
                            <div className="redespd__cont">
                                <div className="contfacebook cr">
                                    <FaFacebook className='iconfacebook iconrd' />
                                    <div className="contfacebook crr">
                                        <a className='enlacesfacebook enlpd' href="" target="_blank" rel="noreferrer" >Facebook</a>
                                        <p className="textofacebook tpd">Contáctenos en nuestra página oficial.</p>
                                    </div>
                                </div>
                                <div className="contwhatsapp cr">
                                    <FaWhatsapp className='iconwhatsapp iconrd' />
                                    <div className="contwhatsapp crr">
                                        <a className='enlaceswhatsapp enlpd' href="" target="_blank" rel="noreferrer" >Whatsapp</a>
                                        <p className="textowhatsapp tpd">CHICLAYO: +51955652350 / +51942102549 <br />
                                            LIMA: +51951061446 / +51951060596</p>
                                    </div>
                                </div>
                                <div className="contgps cr">
                                    <GiGps className='icongps iconrd' />
                                    <div className="contgps crr">
                                        <a className='enlacesgps enlpd' href="" target="_blank" rel="noreferrer" >Visitanos</a>
                                        <p className="textogps tpd">CHICLAYO: Cl. San Pablo Nro. 250 - Chiclayo <br /> LIMA: Av. Luna Pizarro Nro. 179 - Lima</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h1 className='textosomospd' >¿Quienes Somos?</h1>
                        <div className='contsomospd' >

                            <div className="contdesc">
                                <h1 className='contdesc__title' >
                                    Transporte Mabel S.A.C
                                </h1>
                                <p className='contdesc__parrafo' >
                                    Ofrecemos servicios de logística, transporte y distribución con excelencia comprobada. Nuestra ubicación estratégica nos permite ser rápidos en la entrega de servicios en todo el Perú. Contamos con un equipo altamente capacitado y mano de obra certificada. Nos enfocamos en la eficiencia, la puntualidad y la satisfacción del cliente. ¡Permítenos demostrarte por qué somos la mejor opción!
                                </p>
                                <button className="btnconocemas" >
                                    <a href="" className="conocemas">
                                        Conoce más
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contseccionpresentacion">
                <div className="contvalores">
                    <div className="contrapidez dv">
                        <h1 className='contrapidez__title' >Rapidez</h1>
                        <p className='contrapidez__parrafo cp' >Somos una empresa de transportes caracterizada por ofrecer un servicio óptimo en la entrega de su carga.</p>
                    </div>
                    <div className="experiencia dv">
                        <h1 className='experiencia__title' >Experiencia</h1>
                        <p className='experiencia__parrafo cp' >Nuestra experiencia en el manejo de carga a nivel nacional, sienta la confianza de que profesionales cumplirán con sus expectativas.</p>
                    </div>
                    <div className="calidad dv">
                        <h1 className='calidad__title' >Calidad</h1>
                        <p className='calidad__parrafo cp' >Abastecimiento de combustible en nuestras instalaciones, Ubicación estratégica en las rutas.</p>
                    </div>
                    <div className="infraestructura dv">
                        <h1 className='infraestructura__title' >Infraestructura</h1>
                        <p className='infraestructura__parrafo cp' >Abastecimiento de combustible en nuestras instalaciones, Ubicación estratégica en las rutas.</p>
                    </div>
                </div>
                <div className="camionredes">
                    <img className='imagencm' src={Camioncar} alt="" />
                    <div className="contredessoc">
                        <FaFacebook className='iconv' />
                        <FaWhatsapp className='iconv' />
                        <FaYoutube className='iconv' />
                    </div>
                </div>
            </div>
            <Vehiculos />
            <br />
            <div className="lineaamarilla">
                <h2 className='lineatexto' >
                    TRABAJAMOS CON TODAS LAS INDUSTRIAS Y SECTORES
                </h2>
            </div>
            <div className="contvideodesc">
                <iframe width="350" height="250" src="" title="Charla: &quot;¿POR QUÉ LA LOGÍSTICA DEBE SER TEMA PAÍS?&quot;" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div className="contdescvideo">
                    <img className='lbs' src={Logoblacks} alt="" />
                    <p className='contdescvideo__parrafo' >
                        Es una empresa orientada a la prestación de servicios de logística, transporte y distribución. Podemos decir que nuestra experiencia nos respalda ya que venimos cumpliendo todos nuestros objetivos con la responsabilidad y rapidez que nos caracteriza.
                    </p>
                    <button className="btnconocemas" >
                        <a href="" className="conocemas">
                            CONTACTAR
                        </a>
                    </button>
                </div>
            </div>
            <div className="contbannerof">
                <div className="calidadr itembo">
                    <BsFillRocketTakeoffFill className='iconcalidad iconbo' />
                    <h1 className='calidadr__title tl' >Calidad y rapidez</h1> <br />
                    <p className='calidadr__parrafo tp' >Nuestras operaciones son de alta
                        calidad y con la mayor rapidez posible...</p>
                </div>
                <div className="soporteof itembo">
                    <BsFillTelephoneFill className='iconsoporte iconbo' />
                    <h1 className='soporteof__title tl' >Soporte 24/7</h1> <br />
                    <p className='soporteof__parrafo tp' >Chiclayo: +51 955 652 350 <br /> Lima: +51 942 102 549 </p>
                </div>
                <div className="costobajoof itembo">
                    <FaDollarSign className='iconcostobajo iconbo' />
                    <h1 className='costobajoof__title tl' >Costo bajo</h1> <br />
                    <p className='costobajoof__parrafo tp' >Los mejores precios de acuerdo a su negocio.</p>
                </div>
            </div>
            <div className="contenedorbotones">
                <div className="contbotonesllamar">

                    <div className="botonllamarlc">
                        <h2 className='botonllamartexto' >CHICLAYO</h2>
                        <div className="btnlima">
                            <BsFillTelephoneFill className='iconllamar' />
                            <a href="#" className="botonllamar" >(01)-7734802 <br /> +51951061446 <br /> +51951060596</a>
                        </div>
                    </div>

                    <div className="botonllamarlc">
                        <h2 className='botonllamartexto' >LIMA</h2>
                        <div className="btnlima">
                            <BsFillTelephoneFill className='iconllamar' />
                            <a href="#" className="botonllamar" >(074)-638859 <br /> +51955652350 <br /> +51942102549</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footerbanner">
            <h5 className='textofooterb' >Transportes Mabel S.A.C - Diseñado por <span className='spanfooterb' ><a href='https://inticap.com' style={{ color: "#f80001" }} >Inticap</a></span> </h5>
        </div>
        </div>
    );
}

export default Principaldos;